import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "components/style";
import CourseArrowDown from "assets/svgs/inter-arrow.svg";
import ImageZoomComponent from "components/common/image-zoom";
import {
  ButtonWrapper,
  Button,
  Section,
  StyledTag,
} from "components/pages/course-page/offer/style";

import COURSE_STATUSES from "constants/course-statuses";
import { getCourseStatusIcon } from "utilities/course-utils";
import { StyledCourseStatusTag } from "components/pages/course-page/offer/course-tags/style";
import * as SC from "./style";

const MidjourneyOffer = ({ data }) => {
  // offer-illustration
  const image = useStaticQuery(graphql`
    query midjourneyOfferIllustration {
      midjourneyMainIllustration: file(
        relativePath: { eq: "midjourney/midjourney.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const illustrationSrc =
    image.midjourneyMainIllustration.childImageSharp.fluid;

  return (
    <Section>
      <Container>
        <ImageZoomComponent customClass="foundation-illustration">
          <SC.MainIllustrationWrapper>
            <SC.MidjourneyLogoBorder>
              <SC.MidjourneyLogoWrapper>
                <SC.StyledMidjourneyLogo />
              </SC.MidjourneyLogoWrapper>
            </SC.MidjourneyLogoBorder>
            <SC.StyledMainIllustration fluid={illustrationSrc} />
          </SC.MainIllustrationWrapper>
        </ImageZoomComponent>
        <SC.StyledWrapper type={data.coursetype}>
          <SC.Tags>
            <StyledTag
              courseType="Мастер-класс"
              style={{ marginBottom: "1vw" }}
            >
              Мастер-класс
            </StyledTag>
            <StyledCourseStatusTag courseStatus={COURSE_STATUSES.open}>
              {getCourseStatusIcon(true)}В продаже
            </StyledCourseStatusTag>
          </SC.Tags>

          <SC.Info>
            <SC.StyledTitle>
              Granich <span>Midjourney</span>
            </SC.StyledTitle>
            <SC.StyledDescr
              courseTitle={data.coursetitle?.text}
              type={data.coursetype}
            >
              Научимся использовать нейросеть midjourney в качестве инструмента
              графического дизайна
            </SC.StyledDescr>
            <ButtonWrapper>
              <Button
                style={{ background: "var(--granich-midjourney-gradient)" }}
                to="#participation-section"
              >
                Купить
                <CourseArrowDown />
              </Button>
            </ButtonWrapper>
          </SC.Info>
        </SC.StyledWrapper>
      </Container>
    </Section>
  );
};

export default MidjourneyOffer;
